.col-lgs-1{
  flex: 0 0 4.16666%;
  width: 4.16666%;
}

.ft-ss {
  font-size: 1.0em;
}

.ft-s {
  font-size: 1.5em;
}

.ft-m {
  font-size: 2em;
}

.bottom-positioning {
  position: absolute;
  bottom: 0;
  right: 0;
}

.no-button-style {
  background: none;
  border: none;
  padding: 0;
  height: 40px;
}

.circular-border {
  border-radius: 25px;
}

.circle {
  border-radius: 50%;
  aspect-ratio: 1;
}

.circle:hover {
  background-color: green !important;
}

.center-vertically {
  margin-top: auto;
  margin-bottom: auto;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #383838;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2488ff;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2488ff;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 30px;
}

.slider.round:before {
  border-radius: 50%;
}
