.offcanvas-end {
  /* border-radius: 25px;  */
  /* box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5); */
  box-shadow: 10px 10px 20px 5px rgba(0, 0, 0, 0.5);
  /* margin: 2em 1.5em 2em 0; */
  opacity: 0.9;
}

.offcanvas-body {
  padding: 0 !important;
}

.three-dots {
  font-size: 1.5rem;
  line-height: 1;
  color: black;
}

/* 
   *  Removes the padding on the right. This caused the knowledge graph to be visible
   *   on the right side of the right panel (detail panel).
  */
.modal-open {
  padding-right: 0 !important;
}