.new-object{
    position: absolute;
    z-index: 15;
    width: 30%;
}

.new-object-circle{
    position: absolute;
    z-index: 14;
    width: 30%;
}

.circle-node {
    border-radius: 50%;
    aspect-ratio: 1;
  }